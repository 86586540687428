<template>
  <div class="home-container">
    <div class="bottom-btn" @click="onBridgeReady">立即购买</div>
  </div>
</template>

<script>
import {
  getReceiveCoupon,
  getCommonArticle
} from '@/api/user'

import {
  mapState
} from 'vuex'
import {
  getItem,
  setItem
} from '@/utils/storage'

export default {
  name: 'home',

  props: {},
  data() {
    return {

      active: 0, //被激活的列表
      channels: [], //频道列表
      isChannelEditShow: false, //频道编辑层
      navlist: [{
        name: '未使用'
      },
      {
        name: '已使用'
      },
      {
        name: '已失效'
      }

      ],
      list: [],
      loading: false,
      detailid: '',
      data: [],
      finished: false,
      refreshing: false,
      img: '',
    }
  },

  watch: {},
  created() {
    // this.loadChannels()
  },
  mounted() {

    this.onLoad()
  },
  methods: {
    async onLoad() {
      let param = {
        "type": "2",
        'cityId': getItem('userArea').id
      }
      var that = this
      const {
        data
      } = await getCommonArticle(param)
      this.img = data.data[0].picture

    },

    payOrder() {
      this.postRequestWWW(this.baseUrl + '/pay/generalQRCode', {
        sessionId: this.sessionId,
        userId: localStorage.getItem('userId')
      }).then((res) => {
        if (res.data.code === 0) {
          const pay_params = res.data.data
          if (typeof WeixinJSBridge === "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
            }
          } else {
            this.onBridgeReady(pay_params);
          }
        } else {
          alert('微信支付调起失败！');
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    onBridgeReady(params) {
      var that = this


      var params1 = {
        "timeStamp": "1653895632",
        "package": "prepay_id=wx3015271310049070d7fd984727475d0000",
        "appId": "wx0d8fdbc1fc53b4d3",
        "signType": "RSA",
        "nonceStr": "g76712Tz6523no11e46E4718e4u3p59o",
        "paySign": 'sraWwtguFyoWsSKofM3MkAu9RuJHZtXvtSMVEB+S1bigJ0z8ZkyFtFqch3g+UDtJH/YuQBvo1VLXAwM6+wTWeUoZCYmBRxX5FqwFqqvottwquhHPTvlaibJ7AOv0Mqw9mCWARdUcGs1Ybf954Yl//5U6dpXjxdXecQjGJBQxDjfVJKqkHmVgfSAc9CTbA3XKVv582zxK83bx440rBVhbFpLgObzwzt2j4HyAbJN9L8baLogoThmzoTQsFwt35tVIHirid4uFNF8YwAVpq1EAAfAcB3xmhkDp679yI7EUgywD4clHM33NR36AeuEGVgWbqLGwZ9nQFEnud769pxPylQ==',
      }
      alert(JSON.stringify(params1))
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        // {
        //   "appId": 'params.appId',  //公众号名称，由商户传入
        //   "timeStamp": 'params.timeStamp', //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        //   "nonceStr": 'params.nonceStr',  //支付签名随机串，不长于 32 位
        //   "package": 'params.package',//统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        //   "signType": 'MD5',  //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        //   "paySign": 'params.sign', //支付签名
        // },
        params1,

        function (res) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            alert('支付成功！');
            // that.$router.push({path: '/myOrder'})
          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            alert('支付失败！');

          }
        });
    },


    // onUpdateActive(index) {
    //   this.active = index
    // }
  }
}
</script>

<style lang="less">
.img_detail {
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>
